import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Container,
    CssBaseline,
    Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel,
    Grid, Switch, TextField
} from "@material-ui/core";
import DataTable from "react-data-table-component";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import {Add, CheckBox, Create} from "@material-ui/icons";
import ExportButtons from "../../components/ExportButtons";
import React, {useEffect, useState} from "react";
import useStyles from "../../utils/useStyles";
import api from "../../services/api";
import {Autocomplete} from "@material-ui/lab";
import getWeekDays from "../../utils/getWeekDays";
import Swal from "sweetalert2";

export default function Vacation (){
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [open, setOpen]= useState(false);
    const [openEdit, setOpenEdit]= useState(false);
    const [justify, setJustify] = useState([])
    const [employee, setEmployee] = useState([])
    const [justifySelected, setJustifySelected] = useState([])
    const [employeeSelected, setEmployeeSelected] = useState([])
    const [startDate, setStartDate] = useState(moment())
    const [endDate, setEndDate] = useState(moment())
    const [countWeekend, setCountWeekend] = useState(false)
    const [reload, setReload] = useState(true)
    const [editID, setEditID] = useState(null)


    useEffect(()=>{
        if(reload) {
            api.get('/schedules/days').then(res => {
                setData(res.data.data)
                setReload(false)
            })
        }
    },[reload])

    useEffect(()=>{
        api.get('justify').then(res=>{
            setJustify(res.data.data)
        })
    },[])

    useEffect(()=>{
        api.get('employee').then(res=>{
            setEmployee(res.data.data)
        })
    },[])

    const columns = [
        {
            name: "Funcionário",
            selector: row=>row.employee.name,
            sortable: true,
            width:'20%',
        },
        {
            name: "Data de Inicio",
            selector: row=>row.startDate,
            sortable: true,
            width:'10%',
        },
        {
            name: "Data de Fim",
            selector: row=>row.endDate,
            sortable: true,
            width:'10%',
        },
        {
            name: "Conta os Fins de semana",
            // eslint-disable-next-line
            selector: row=>row.countWeekend==1?"Sim":"Não",
            sortable: true,
            width:'15%',
        },
        {
            name: "Numero de dias",
            selector: row=>row.numberDay,
            sortable: true,
            width:'10%',
        },
        {
            name: "Motivo",
            selector: row=>row.justify.name,
            sortable: true,
            width:'20%',
        },
        {
            name: "Editar",
            selector: row=><Button onClick={()=>setEdit(row)} color={"primary"} variant={"contained"}><Create/></Button>,
            sortable: true,
            width:'10%',
        },
    ]

    const setEdit = (row) =>{
        setStartDate(moment(row.startDate, 'YYYY-MM-DD'))
        setEndDate(moment(row.endDate, 'YYYY-MM-DD'))
        setEmployeeSelected(row.employee)
        setJustifySelected(row.justify)
        setCountWeekend(Boolean(row.countWeekend))
        setOpenEdit(true)
        setEditID(row.id)
    }

    const setCloseEdit = () =>{
        setStartDate(moment())
        setEndDate()
        setEmployeeSelected({})
        setJustifySelected({})
        setCountWeekend(false)
        setOpenEdit(false)
    }

    const save = ()=>{
        let countDays
        // eslint-disable-next-line
        if(countWeekend==false)
            countDays = getWeekDays(startDate.format("YYYY-MM-DD HH:mm:ss"), endDate.format("YYYY-MM-DD HH:mm:ss"))
        else
            countDays = endDate.diff(startDate, "days")+1
        if(editID!==null) {
            api.put('/schedules/days/' + editID, {
                emplyee: employeeSelected.id,
                justification_id: justifySelected.id,
                numberDay: countDays,
                countWeekend,
                startDate, endDate
            }).then(res => {
                setOpenEdit(false)
                // eslint-disable-next-line
                if (res.data.code == 0) {
                    Swal.fire({
                        icon: "success",
                        text: res.data.message,
                        timerProgressBar: true,
                        timer: 1500
                    })
                    setReload(true)
                    setOpen(false)
                } else {
                    Swal.fire({
                        icon: "error",
                        text: res.data.message,
                        timerProgressBar: true,
                        timer: 1500
                    })
                }
            })
        }else{
            api.post('/schedules/days', {
                emplyee: employeeSelected.id,
                justification_id: justifySelected.id,
                numberDay: countDays,
                countWeekend,
                startDate, endDate
            }).then(res => {
                setOpenEdit(false)
                // eslint-disable-next-line
                if (res.data.code == 0) {
                    Swal.fire({
                        icon: "success",
                        text: res.data.message,
                        timerProgressBar: true,
                        timer: 1500
                    })
                    setReload(true)
                } else {
                    Swal.fire({
                        icon: "error",
                        text: res.data.message,
                        timerProgressBar: true,
                        timer: 1500
                    })
                }
            })
        }
    }

    return(
        <>
            <Container component="main" maxWidth="xs">
                <MuiPickersUtilsProvider libInstance={moment}  utils={MomentUtils} locale={"pt"}>
                    <CssBaseline />
                    <div className={classes.paper}>
                        <Card className={classes.root}>
                            <CardHeader
                                title={<Grid container>
                                    <Grid item xs={3}>
                                    <span>Ferias / Justificações</span>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button onClick={()=>setOpen(true)} children={<Add/>} color={"primary"} variant={"contained"}/>
                                    </Grid>
                                </Grid>}
                                action={
                                    <>
                                        {/*<KeyboardDatePicker
                                            variant={"outlined"}
                                            ampm={false}
                                            //views={['year', 'month','']}
                                            //inputFormat={"DD/MM/YYYY"}
                                            format={"DD/MM/YYYY"}
                                            label="Selecionar Data"
                                            maxDate={moment()}
                                            //value={day}
                                            onChange={(newValue) => {
                                                setDay(newValue);
                                            }}

                                        />*/}
                                    </>}
                            />
                            <CardContent>
                                <Grid container spacing={1}>
                                    <DataTable
                                        columns={columns}
                                        data={data}
                                        sortIcon={<ArrowDownwardIcon/>}
                                        pagination
                                        selectableRowsComponent={CheckBox}
                                        noDataComponent={"Não foi encontrado registo de ferias"}
                                        progressComponent={<CircularProgress/>}
                                        paginationComponentOptions={{
                                            rowsPerPageText: 'Linhas por pagina:',
                                            rangeSeparatorText: 'de',
                                            noRowsPerPage: false,
                                            selectAllRowsItem: false,
                                            selectAllRowsItemText: 'Todas'
                                        }}
                                        subHeader
                                        subHeaderComponent={<ExportButtons data={data} columns={columns} name={"Registos"/*+day.format("MMMM")*/}/>}
                                    />
                                </Grid>
                            </CardContent>
                        </Card>
                    </div>
                    <Dialog
                        open={open}
                        onClose={()=>setOpen(false)}
                        style={{height:'100%'}}
                    >
                        <DialogTitle>{"Registo de Ferias / Justificações"}</DialogTitle>
                        <DialogContent style={{overflowY:'hidden'}}>
                            <Grid container spacing={4} >
                                <Grid item xs={6}>
                                    <Autocomplete
                                        onChange={(e, newValue)=> {
                                            setEmployeeSelected(newValue)
                                        }}
                                        value={employeeSelected}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => <TextField {...params} label="Funcionário" />}
                                        options={employee}
                                        noOptionsText={"Não foi encontrado funcionários"}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Autocomplete
                                        onChange={(e, newValue)=> {
                                            setJustifySelected(newValue)
                                        }}
                                        value={justifySelected}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => <TextField {...params} label="Justificação" />}
                                        options={justify}
                                        noOptionsText={"Não foi encontradas justificações"}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <KeyboardDatePicker
                                        variant={"outlined"}
                                        ampm={false}
                                        //views={['year', 'month','']}
                                        //inputFormat={"DD/MM/YYYY"}
                                        format={"DD/MM/YYYY"}
                                        label="Data de inicio"
                                        maxDate={moment()}
                                        value={startDate}
                                        onChange={(newValue) => {
                                               setStartDate(newValue);
                                        }}

                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <KeyboardDatePicker
                                        variant={"outlined"}
                                        ampm={false}
                                        //views={['year', 'month','']}
                                        //inputFormat={"DD/MM/YYYY"}
                                        format={"DD/MM/YYYY"}
                                        label="Data de fim"
                                        //maxDate={moment()}
                                        value={endDate}
                                        onChange={(newValue) => {
                                            setEndDate(newValue);
                                        }}

                                    />
                                </Grid>
                                <Grid xs={6} item>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={countWeekend}
                                                onChange={()=>setCountWeekend(!countWeekend)}
                                                color="primary"
                                                name="checkedB"
                                            />
                                        }
                                        label={"Contabiliza Fins de semana"}
                                    />


                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={()=>setOpen(false)} color="secondary">
                                Cancelar
                            </Button>

                            <Button onClick={save} color="primary">
                                Guardar
                            </Button>
                        </DialogActions>
                    </Dialog>


                    <Dialog
                        open={openEdit}
                        onClose={setCloseEdit}
                        style={{height:'100%'}}
                    >
                        <DialogTitle>{"Editar de Ferias / Justificações"}</DialogTitle>
                        <DialogContent style={{overflowY:'hidden'}}>
                            <Grid container spacing={4} >
                                <Grid item xs={6}>
                                    <Autocomplete
                                        onChange={(e, newValue)=> {
                                            setEmployeeSelected(newValue)
                                        }}
                                        value={employeeSelected}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => <TextField {...params} label="Funcionário" />}
                                        options={employee}
                                        noOptionsText={"Não foi encontrado funcionários"}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Autocomplete
                                        onChange={(e, newValue)=> {
                                            setJustifySelected(newValue)
                                        }}
                                        value={justifySelected}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => <TextField {...params} label="Justificação" />}
                                        options={justify}
                                        noOptionsText={"Não foi encontradas justificações"}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <KeyboardDatePicker
                                        variant={"outlined"}
                                        ampm={false}
                                        //views={['year', 'month','']}
                                        //inputFormat={"DD/MM/YYYY"}
                                        format={"DD/MM/YYYY"}
                                        label="Data de inicio"
                                        maxDate={moment()}
                                        value={startDate}
                                        onChange={(newValue) => {
                                            setStartDate(newValue);
                                        }}

                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <KeyboardDatePicker
                                        variant={"outlined"}
                                        ampm={false}
                                        //views={['year', 'month','']}
                                        //inputFormat={"DD/MM/YYYY"}
                                        format={"DD/MM/YYYY"}
                                        label="Data de fim"
                                        //maxDate={moment()}
                                        value={endDate}
                                        onChange={(newValue) => {
                                            setEndDate(newValue);
                                        }}

                                    />
                                </Grid>
                                <Grid xs={6} item>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={countWeekend}
                                                onChange={()=>setCountWeekend(!countWeekend)}
                                                color="primary"
                                                name="checkedB"
                                            />
                                        }
                                        label={"Contabiliza Fins de semana"}
                                    />


                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={setCloseEdit} color="secondary">
                                Cancelar
                            </Button>

                            <Button onClick={save} color="primary">
                                Guardar
                            </Button>
                        </DialogActions>
                    </Dialog>
                </MuiPickersUtilsProvider>
            </Container>
        </>
    )
}