import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";


export default function getTimeWorkSchedule({schedule}){
    momentDurationFormatSetup(moment);
    let oldPeriods = []
    let timeWork = 0
    // eslint-disable-next-line array-callback-return
    schedule.periods.map(row=>{
        if(!oldPeriods.includes(row.id)) {
            oldPeriods.push(row.id)
            let start = moment(row.timeStart, 'HH:mm');
            let end = moment(row.timeEnd, 'HH:mm');
            let diff = end.diff(start, 'seconds')
            timeWork+=diff
        }
    })

    let time = moment.duration(timeWork, 'seconds').format('HH:mm');
    // eslint-disable-next-line
    if(schedule.flexible==1){
        time+="("+schedule.periods[0].break+"h pausa)"
    }
    return time!=="00" ? time : "";

}