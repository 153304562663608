import moment from "moment";
import MomentUtils from "@date-io/moment";
import {Card, CardContent, CardHeader, CircularProgress, Container, CssBaseline, Grid} from "@material-ui/core";
import  {MuiPickersUtilsProvider, KeyboardDatePicker} from "@material-ui/pickers";
import DataTable from "react-data-table-component";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import {CheckBox} from "@material-ui/icons";
import React, {useEffect, useState} from "react";
import useStyles from "../../utils/useStyles";
import api from "../../services/api";
import getTimeWorkReport from "../../utils/getTimeWorkReport";
import range from "../../utils/range";
import getTimeWorkSchedule from "../../utils/getTimeWorkSchedule";
import Select from "react-select";
import ExportButtons from "../../components/ExportButtons";
import {useRecoilState} from "recoil";
import {backdrop} from "../../atom";

export default function ReportsMonth(){
    const classes = useStyles();
    const [data, setData] = useState([])
    const [tmpData, setTmpData] = useState()
    const [columns, setColumns] = useState([])
    const [employees, setEmployees] = useState([])
    const [employeeSelected, setEmployeeSelected] = useState({value:"", label:""})
    const [day, setDay] = useState(moment())
    const [, setLoader] = useRecoilState(backdrop);

    useEffect(()=>{
        if(day.isValid()) {
            setLoader(true)
            api.post('reports/month', {
                employee: employeeSelected.value,
                start:day.startOf('month').format('YYYY-MM-DD'),
                end:day.endOf('month').format('YYYY-MM-DD')
            }).then(res => {
                setTmpData(res.data.data)
                setLoader(false)
            })
        }
        // eslint-disable-next-line
    },[day, employeeSelected])

    useEffect(()=>{
        let max = 0
        let oldMax = 0
        let newDate = []
        if(tmpData!==undefined){
            tmpData.map((employee,idx)=>{
                max = employee.picking.length
                newDate.push({...employee,periods:setPeriods(max, employee)})
                if(max>=oldMax){
                    oldMax=max
                }
                return null
            })
            let cools = []
            // eslint-disable-next-line array-callback-return
            range(oldMax/2).map((row,idx)=>{
                cools.push({
                    name: "Período "+(idx+1),
                    selector: (row)=>showPeriods(row,idx),
                    sortable: true,
                    width:'30%',
                })
            })
            setColumns([
                {
                    name: "Dia",
                    selector: row=>row.day,
                    sortable: true,
                    width:'15%',
                },
                ...cools,
                {
                    name:"Tempo de trabalho",
                    selector: getTimeWorkReport,
                    sortable: true,
                    width: '15%'
                },
                {
                    name:"Horas previstas",
                    selector: getTimeWorkSchedule,
                    sortable: true,
                    width: '15%'
                }
            ])
        }
        setData(newDate)
        return ""
    },[tmpData])

    // Get employees
    useEffect(()=>{
        api.get('employee').then(res => {
            let employeesTmp = []
            // eslint-disable-next-line array-callback-return
            res.data.data.map((employee,idx)=>{
                employeesTmp.push({value:employee.id,label:employee.name})
            })
            setEmployees(employeesTmp)
        })
    },[])


    const setPeriods = (max, row)=>{
        let sendData = []
        let used = []
        // eslint-disable-next-line array-callback-return
        row.picking.map(picking=>{
            let nextData=picking
            if(picking.after!==null){
                //max++
                // eslint-disable-next-line array-callback-return
                row.picking.map(row=>{
                    // eslint-disable-next-line
                    if(row.id==picking.after){
                        nextData.after = row
                        used.push(row.id)
                    }
                })
                //sendData.push(nextData)
            }
            if(!used.includes(picking.id))
                sendData.push(nextData)
        })
        if(sendData.length<max){
            sendData.concat(range(max-sendData.length))
        }
        return sendData
    }

    const showPeriods = (row,idx)=>{
        let resp = ""
        if(row.periods[idx]!==undefined) {
            resp += moment(row.periods[idx].timeEntry).format("HH:mm")
            if (row.periods[idx].after!==null){
                resp += " / " + moment(row.periods[idx].after.timeEntry).format("HH:mm")
            }
        }
        return resp

    }

    return (
        <>
            <Container component="main" maxWidth="xs" style={{height:'100%'}}>
                <MuiPickersUtilsProvider libInstance={moment}  utils={MomentUtils} locale={"pt"}>
                    <CssBaseline />
                    <div className={classes.paper}>
                        <Card className={classes.root}>
                            <CardHeader
                                title={"Relatório Mensal"}
                                action={
                                    <Grid container spacing={4}>
                                        <Grid item xs={6}>
                                            <Select
                                                options={employees}
                                                value={employeeSelected}
                                                onChange={(e)=>setEmployeeSelected(e)}
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                placeholder="Selecione o funcionário"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <KeyboardDatePicker
                                            inputVariant={"outlined"}
                                            ampm={false}
                                            variant={"inline"}
                                            views={['month','']}
                                            //inputFormat={"DD/MM/YYYY"}
                                            format={"MM/YYYY"}
                                            label="Selecionar Data"
                                            maxDate={moment().endOf('month')}
                                            value={day}
                                            onChange={(newValue) => {
                                                setDay(newValue);
                                            }}

                                            />
                                        </Grid>
                                    </Grid>}
                            />
                            <CardContent>
                                <Grid container spacing={1}>
                                    <Grid title={true} sm={12}>
                                        <Card>
                                            <CardContent>
                                            {employeeSelected.value!==""?(
                                                <DataTable

                                                    columns={columns}
                                                    data={data}
                                                    sortIcon={<ArrowDownwardIcon/>}
                                                    pagination
                                                    paginationPerPage={31}
                                                    selectableRowsComponent={CheckBox}
                                                    noDataComponent={"Não foram encontrados registos"}
                                                    progressComponent={<CircularProgress/>}
                                                    paginationComponentOptions={{
                                                        rowsPerPageText: 'Linhas por pagina:',
                                                        rangeSeparatorText: 'de',
                                                        noRowsPerPage: false,
                                                        selectAllRowsItem: false,
                                                        selectAllRowsItemText: 'Todas'
                                                    }}
                                                    subHeader
                                                    subHeaderComponent={<ExportButtons data={data} columns={columns} name={"Relatorio Mensal de "+day.format("MMMM")+" de "+employeeSelected.label}/>}
                                                />
                                            ):(
                                                <CardHeader title={"Por favor selecione um funcionário"}/>
                                            )}
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </div>
                </MuiPickersUtilsProvider>
            </Container>
        </>
    )
}