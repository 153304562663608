import * as XLSX from "xlsx";
import { saveAs } from 'file-saver';

export function generateExcel(collum, data, name="Relatorio"){
    let processData = renderData(collum,data);
    renderExcel(processData, name)
}

export function convertColumns(column){
    let data = []
    // eslint-disable-next-line array-callback-return
    column.map((value,idx)=>{
        if(value.name!=="Editar")
            data.push({
                name:value.name,
                key:idx
            })
    })
    return data
}

export function renderData (column, data){
    let send = [[...getNameColumn(column)]]
    send.push(...runSelector(data,column))
    return send
}

function getNameColumn(column) {
    let data = []
    // eslint-disable-next-line array-callback-return
    column.map((value,idx)=>{
        data.push(
            value.name,
        )
    })
    return data
}

function runSelector(data=[], column=[]) {
    let sendData = []
    // eslint-disable-next-line array-callback-return
    data.map((row)=>{
        let nextData = []
        // eslint-disable-next-line array-callback-return
        column.map((col)=>{
            if(typeof col.selector==="function")
                if(col.selector(row)!=="[object Object]")
                    nextData.push(col.selector(row))
                else
                    nextData.push("")
            else
                nextData.push("")
        })
        sendData.push(nextData)
    })
    return sendData
}

export function renderExcel(data, name="Relatorio"){
    let wb = XLSX.utils.book_new();
    wb.Props = {
        Title: name,
        Subject: name,
        Author: "Capensis LDA",
        CreatedDate: new Date()
    };
    wb.SheetNames.push(name);
    wb.Sheets[name] = XLSX.utils.aoa_to_sheet(data);
    const wbout = XLSX.write(wb, {bookType: 'xlsx', type: 'binary'});
    saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), name+'.xlsx');

}

function s2ab(s) {
    const buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    const view = new Uint8Array(buf);  //create uint8array as viewer
    for (let i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
    return buf;
}