import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Container,
    CssBaseline, Divider,
    Grid, IconButton,
    Typography
} from "@material-ui/core";
import useStyles from "../../utils/useStyles";
import {companyName, removeCompanyToken} from "../../services/auth";
import {useEffect, useState} from "react";
import api from "../../services/api";
import Swal from "sweetalert2";
import {Backspace, ExitToApp, Fingerprint} from "@material-ui/icons";
import {useRecoilState} from "recoil";
import {backdrop} from "../../atom";

export default function Picking(props) {
    const classes = useStyles();

    const [time, setTime] = useState(new Date());
    const [code, setCode] = useState("");
    const [hiddenChar, setHiddenChar] = useState("");
    const [, setLoader] = useRecoilState(backdrop);

    useEffect(() => {
        setInterval(() => {
            setTime(new Date());
        }, 1000);
    }, []);

    useEffect(()=>{
        let txt = "";
        for(let i = 0; i < code.length; i++){
            txt += "*";
        }

        setHiddenChar(txt)
    },[code])

    function callApi(position=null){
        setLoader(true)
        api.post('picking',{
            code,
            coordinates:position!==null?position.coords.latitude+", "+position.coords.longitude:""
        }).then(res=>{
            setLoader(false)
            if(res.data.code===0){
                Swal.fire({
                    icon: "success",
                    text: res.data.message,
                    timerProgressBar:true,
                    timer:3000
                })
            }else{
                Swal.fire({
                    icon: "error",
                    text: res.data.message,
                    timerProgressBar:true,
                    timer:3000
                })
            }
            setCode("")
        }).catch(()=>{
            setLoader(false)
            Swal.fire({
                icon: "error",
                text: "Ocurreu um erro a picar o ponto, por favor verifique a ligação à internet",
                timerProgressBar:true,
                timer:3000
            })
        })
    }

    function removeCompany(){
        Swal.fire({
            title: 'Deseja sair do modo picagem?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#002c4d',
            cancelButtonColor: '#873d48',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(result=>{
            if (result.isConfirmed) {
                removeCompanyToken();
                props.history.push("/");
            }
        })
    }


    function pick(){
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(function(position) {
                callApi(position)
            },()=>callApi(null)
            );
        } else {
            callApi(null)
        }
    }

    const setNumber = (number,oldCode) => {
        console.log(number)
        if(number==="apagar"){
            setCode(oldCode.substring(0,oldCode.length-1))
        }else if(number==="delete"){
            setCode("")
        }else{
            setCode(oldCode+number)

        }
    }

    return(
        <Container component="main" maxWidth="xs" className="noselect">
            <CssBaseline />
            <div className={classes.paper}>
                <Card className={classes.root}>

                    <CardContent>
                        <CardHeader
                            titleTypographyProps={{class:classes.specialTitle}}
                            style={{color: "#002c4d"}}
                            title={"Picar o ponto"}
                            className={"titleSpecial"}
                            action={<IconButton onClick={removeCompany} style={{marginTop:12,color:'#873d48'}}><ExitToApp/></IconButton>}
                        />
                        <Grid container spacing={1}>
                            <Grid item xs={12} style={{margin:20}}>
                                <Typography color={"primary"} component="h1" variant="h3" >
                                    <center className={"titleSpecial"}>{ companyName().charAt(0).toUpperCase() + companyName().slice(1) }</center>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{margin:20}}>
                                <center>
                                    <Typography className={"titleSpecial"} color={"primary"} variant={"h3"} style={{alignSelf:"center"}}>{time.getHours()+":"+(time.getMinutes()<10?"0"+time.getMinutes():time.getMinutes())}</Typography>
                                    <Typography className={"titleSpecial"} color={"primary"} variant={"h5"} style={{alignSelf:"center"}}>{time.getDate()+"/"+((time.getMonth()+1)<10?("0"+(time.getMonth()+1)):time.getMonth()+1)+"/"+(time.getFullYear())}</Typography>
                                </center>
                            </Grid>
                            <Grid item xs={12} style={{margin:20}}>
                                <center><Typography  className={"titleSpecial"} color={"primary"} variant={"h5"} style={{alignSelf:"center"}}>Código de funcionário</Typography></center>
                                <center><Typography  className={"titleSpecial"} color={"primary"} variant={"h5"} style={{alignSelf:"center",height:25, marginTop:15}}>{hiddenChar}</Typography></center>
                                <Divider color={"primary"} style={{marginBottom:10}}/>
                            </Grid>
                            <Grid container spacing={2}>
                                {/*<Grid item xs={12} style={{width:'100%', height:'75px', fontSize:'29px'}}>{desconto}</Grid>*/}
                                <Grid item xs={4}><Button onClick={()=>setNumber("1", code)} variant={"contained"} color={"primary"} style={{width:'100%', height:'50px', fontSize:'25px',fontFamily:'Zekton Rg'}}><b>1</b></Button></Grid>
                                <Grid item xs={4}><Button onClick={()=>setNumber("2", code)} variant={"contained"} color={"primary"} style={{width:'100%', height:'50px', fontSize:'25px',fontFamily:'Zekton Rg'}}><b>2</b></Button></Grid>
                                <Grid item xs={4}><Button onClick={()=>setNumber("3", code)} variant={"contained"} color={"primary"} style={{width:'100%', height:'50px', fontSize:'25px',fontFamily:'Zekton Rg'}}><b>3</b></Button></Grid>
                                <Grid item xs={4}><Button onClick={()=>setNumber("4", code)} variant={"contained"} color={"primary"} style={{width:'100%', height:'50px', fontSize:'25px',fontFamily:'Zekton Rg'}}><b>4</b></Button></Grid>
                                <Grid item xs={4}><Button onClick={()=>setNumber("5", code)} variant={"contained"} color={"primary"} style={{width:'100%', height:'50px', fontSize:'25px',fontFamily:'Zekton Rg'}}><b>5</b></Button></Grid>
                                <Grid item xs={4}><Button onClick={()=>setNumber("6", code)} variant={"contained"} color={"primary"} style={{width:'100%', height:'50px', fontSize:'25px',fontFamily:'Zekton Rg'}}><b>6</b></Button></Grid>
                                <Grid item xs={4}><Button onClick={()=>setNumber("7", code)} variant={"contained"} color={"primary"} style={{width:'100%', height:'50px', fontSize:'25px',fontFamily:'Zekton Rg'}}><b>7</b></Button></Grid>
                                <Grid item xs={4}><Button onClick={()=>setNumber("8", code)} variant={"contained"} color={"primary"} style={{width:'100%', height:'50px', fontSize:'25px',fontFamily:'Zekton Rg'}}><b>8</b></Button></Grid>
                                <Grid item xs={4}><Button onClick={()=>setNumber("9", code)} variant={"contained"} color={"primary"} style={{width:'100%', height:'50px', fontSize:'25px',fontFamily:'Zekton Rg'}}><b>9</b></Button></Grid>
                                <Grid item xs={4}><Button onClick={()=>setNumber("delete", code)} variant={"contained"} color={"secondary"} style={{width:'100%', height:'50px', fontSize:'25px',fontFamily:'Zekton Rg'}}><b>C</b></Button></Grid>
                                <Grid item xs={4}><Button onClick={()=>setNumber("0", code)} variant={"contained"} color={"primary"} style={{width:'100%', height:'50px', fontSize:'25px',fontFamily:'Zekton Rg'}}><b>0</b></Button></Grid>
                                <Grid item xs={4}><Button onClick={()=>setNumber("apagar", code)} variant={"contained"} color={"secondary"} style={{width:'100%', height:'50px', fontSize:'25px', paddingTop:15}}><b><Backspace/></b></Button></Grid>
                            </Grid>
                            <Grid item xs={12} style={{height:'100%'}} >
                                <center>
                                    <Button style={{height:200, width:200, borderRadius:'100%', iconSizeMedium:"300px", marginTop:10}} onClick={pick} color={"primary"} variant={"contained"}  >
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Fingerprint style={{fontSize:100}}/>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant={"h3"} style={{alignSelf:"center",fontSize:25,fontFamily:'Zekton Rg',}}>
                                                    Realizar ponto
                                                </Typography>
                                            </Grid>
                                        </Grid>


                                    </Button>
                                </center>
                            </Grid>
                            {/*<Grid item xs={12} style={{height:'100%'}} >
                                <Button onClick={removeCompany} color={"primary"} variant={"outlined"} fullWidth >Sair</Button>
                            </Grid>*/}
                        </Grid>
                    </CardContent>
                </Card>
            </div>
        </Container>
    )
}