import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";


export default function getTimeWorkReport({picking}){
    momentDurationFormatSetup(moment);

    let timeWork = 0
    // eslint-disable-next-line array-callback-return
    picking.map(row=>{
        if(row.after!==null){
            console.warn(row.timeEntry)
            let start = moment(row.timeEntry);
            let end = moment(row.after.timeEntry);
            let diff = end.diff(start, 'seconds')
            timeWork+=diff
        }
    })
    let time = moment.duration(timeWork, 'seconds').format('HH:mm');
    return time!=="00" ? time : "";
}