/*export default function range(size, startAt = 0) {
    console.error(size)
    return [...Array(Math.abs(size).toFixed(0)).keys()].map((i, idx) => idx);
}*/

export default function range(size, startAt = 0) {
    console.log(size)
    return [...Array(Math.round(size)).keys()].map(i => i + startAt);
}


/*
* range(9)
*
* return [0, 1, 2, 3, 4, 5, 6, 7, 8]
* */