import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import {CardContent, Dialog} from "@material-ui/core";
import {ArrowLeft, ArrowRight, Group, Save} from "@material-ui/icons";
import api from "../services/api";
import Swal from "sweetalert2";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
    },
    cardHeader: {
        padding: theme.spacing(1, 2),
    },
    list: {
        width: 200,
        height: 230,
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
}));

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}

function removeDuplicated(list,toRemove){
    let filtered=[];
    // eslint-disable-next-line array-callback-return
    list.map((row)=>{
        let remove = false;
        // eslint-disable-next-line array-callback-return
        toRemove.map(el=>{
             if(el.id===row.id)
                 remove=true
        })
        if(!remove){
            filtered.push(row)
        }

    })
    return filtered
}

export default function SetEmployeeDepartment({employee=[], employeeDepartment=[],department=0, reload=()=>null}) {

    const classes = useStyles();
    const [checked, setChecked] = React.useState([]);
    const [list, setList] = React.useState([]);
    const [selected, setSelected] = React.useState([]);

    const [open, setOpen] = React.useState(false)

    const leftChecked = intersection(checked, list);
    const rightChecked = intersection(checked, selected);


    useEffect(()=>{
        setSelected(employeeDepartment)
        setList( removeDuplicated(employee,employeeDepartment))
    },[employeeDepartment,employee])

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const handleCheckedRight = () => {
        setSelected(selected.concat(leftChecked));
        setList(not(list, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setList(list.concat(rightChecked));
        setSelected(not(selected, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const customList = (title, items) => (
        <Card style={{minWidth:'100%'}}>
            <CardHeader
                className={classes.cardHeader}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                        disabled={items.length === 0}
                    />
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} Selecionado/s`}
            />
            <Divider />
            <List className={classes.list} dense component="div" role="list">
                {items.map((value) => {
                    const labelId = `transfer-list-all-item-${value.id}-label`;

                    return (
                        <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={`${value.name}`} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Card>
    );

    const save=()=>{
        api.put('department/move/'+department,{
            employeeList:selected
        }).then(async res=>{
            if(res.data.code===0){
                setOpen(false)
                reload()
                await Swal.fire({
                    icon: 'success',
                    title: res.data.message,
                    timer: 1000,
                    timerProgressBar:true,
                })
            }else{
                setOpen(false)
                await Swal.fire({
                    icon: 'error',
                    title: res.data.message,
                    timer: 1000,
                    timerProgressBar:true,
                })
                setOpen(true)
            }
        })
    }

    return (
        <>
            <Button color={"primary"} variant={"outlined"} onClick={()=>setOpen(true)}><Group/></Button>
            <Dialog  maxWidth={"lg"} open={open} onClose={()=>setOpen(false)} >
                <Card >
                    <CardContent>
                        <CardHeader title={"Departamento"}/>
                        <Grid
                            container
                            spacing={2}
                            justifyContent="center"
                            alignItems="center"
                            className={classes.root}
                        >
                            <Grid item xs={12} sm={4}>{customList('Funcionários', list)}</Grid>
                            <Grid item sm={2}>
                                <Grid container direction="column" alignItems="center">
                                    <Button
                                        variant="outlined"
                                        className={classes.button}
                                        onClick={handleCheckedRight}
                                        disabled={leftChecked.length === 0}
                                    >
                                        <ArrowRight/>
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        className={classes.button}
                                        onClick={handleCheckedLeft}
                                        disabled={rightChecked.length === 0}
                                    >
                                        <ArrowLeft/>
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={4}>{customList('Departamento', selected)}</Grid>
                            <Grid item xs={12}><center><Button onClick={save} color={"primary"} variant={"contained"}><Save/></Button></center></Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Dialog>
        </>
    );
}