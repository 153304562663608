import {
    Button,
    Card,
    CardContent, CardHeader,
    CircularProgress,
    Container,
    CssBaseline,
    Dialog, DialogActions, DialogContent, DialogTitle,
    Grid,Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from "@material-ui/core";
import DataTable from "react-data-table-component";
import {CheckBox} from "@material-ui/icons";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import React, {useEffect, useState} from "react";
import api from "../../services/api";
import {Link} from "react-router-dom";
import CreateIcon from '@material-ui/icons/Create';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import getTimeWork from "../../utils/getTimeWork";
import useStyles from "../../utils/useStyles";
import {useRecoilState} from "recoil";
import {backdrop} from "../../atom";

export default function EmployeeList() {
    const classes = useStyles();

    const [employee, setEmployee] = useState([]);
    const [schedules, setSchedules] = useState([]);
    const [schedulesShow, setSchedulesShow] = useState(false);
    const [, setLoader] = useRecoilState(backdrop);

    const columns = [
        {
            name: "Nº",
            selector: row=>row['number'],
            sortable: true,
            width:'7%'
        },{
            name: "Nome",
            selector: row=>row['name'],
            sortable: true,
            //width:'10%'
        },
        {
            name: "Departamento",
            selector: row=>row.department!==null?row.department.name:"Sem departamento associado",

            sortable: true,
            //width:'60%'
        },
        {
            name: "Código",
            selector:row=>row.code,
            sortable: true,
        },
        {
            name: "Horário",
            cell: row=>getScheduleDescription(row),
            sortable: false,
        },
        {
            name: "Ações",
            cell: row => <>
                <Link to={"/employee/" + row.id}><Button title={"Editar"} style={{margin:5}} variant={"contained"} color={"primary"}><CreateIcon/></Button></Link>
                <Button style={{margin:5}} onClick={()=>showSchedules(row.schedule)} title={"Horário"} variant={"outlined"} color={"primary"}><AccessTimeIcon/></Button>
            </>
        }
    ];
    function getScheduleDescription(row){
        let description = "";
        if(row.schedule.length!==0){
            // eslint-disable-next-line
            row.schedule.map(schedule=>{
                description+=schedule.description+", "
            })
            return description.substr(0, description.length - 2);
        }else{
            return "Sem horário associado"
        }

    }

    useEffect(()=>{
        setLoader(true)
        api.get('employee').then(res=>{
            setEmployee(res.data.data)
            setLoader(false)
        })
        // eslint-disable-next-line
    },[])

    function showSchedules (info){
        setSchedules(info)
        setSchedulesShow(true)
    }

    return(
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Card className={classes.root}>
                    <CardHeader
                        title={"Funcionários"}
                        action={<Link to={"/employee/new"}><Button variant={"contained"} color={"primary"}><PersonAddIcon/></Button></Link>}
                    />
                    <CardContent>
                        <Grid container spacing={1}>
                            <DataTable
                                columns={columns}
                                data={employee}
                                sortIcon={<ArrowDownwardIcon/>}
                                pagination
                                selectableRowsComponent={CheckBox}
                                noDataComponent={"Não foi encontrados funcionários"}
                                progressComponent={<CircularProgress/>}
                                paginationComponentOptions={{
                                    rowsPerPageText: 'Linhas por pagina:',
                                    rangeSeparatorText: 'de',
                                    noRowsPerPage: false,
                                    selectAllRowsItem: false,
                                    selectAllRowsItemText: 'Todas'
                                }}
                            />
                        </Grid>
                    </CardContent>
                </Card>
            </div>
            <Dialog onClose={()=>setSchedulesShow(false)} open={schedulesShow} sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}>
                <DialogTitle>Horários</DialogTitle>
                <DialogContent dividers>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell><b>Descrição</b></TableCell>
                                    <TableCell><b>Dias de trabalho</b></TableCell>
                                    <TableCell><b>Períodos de trabalho</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {schedules.map((row, idx)=> {
                                    console.log(row.schedule.periods)
                                    return (
                                        <TableRow key={idx}>
                                            <TableCell>{row.schedule.name}</TableCell>
                                            <TableCell>{row.description}</TableCell>
                                            <TableCell>{getTimeWork(row.schedule.periods,row.schedule)}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                   {/* <List>
                        {schedules.map((row,idx)=>{
                            return(
                                <ListItem key={idx} button onClick={()=>setCompanyUse(row.token,row.name)}>{row.name}</ListItem>
                            )
                        })}
                    </List>*/}
                    <DialogActions>
                        <Button color={"primary"} onClick={()=>setSchedulesShow(false)}>Fechar</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </Container>
    )
}