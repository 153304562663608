import {
    Button,
    Card,
    CardContent, CardHeader,
    CircularProgress,
    Container,
    CssBaseline, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText,
    Grid, IconButton, InputLabel, Menu, MenuItem, Select, TextField,
} from "@material-ui/core";
import DataTable from "react-data-table-component";
import {CheckBox, Delete} from "@material-ui/icons";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import React, {useEffect, useState} from "react";
import api from "../../services/api";
import useStyles from "../../utils/useStyles";
import moment from "moment";
import {Autocomplete} from "@material-ui/lab";
import {TimePicker, MuiPickersUtilsProvider, DatePicker} from "@material-ui/pickers";
import "moment/locale/pt";
import Swal from "sweetalert2";
import MomentUtils from "@date-io/moment";
import ExportButtons from "../../components/ExportButtons";
import {useRecoilState} from "recoil";
import {backdrop} from "../../atom";
import MenuIcon from "@material-ui/icons/Menu";

export default function InconsistenciesList() {

    const classes = useStyles();
    const [inconsistencies, setInconsistencies] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [date, setDate] = useState(moment());
    const [observation, setObservations] = useState("")
    const open = Boolean(anchorEl);
    const [pointSelected,setPointSelected] = useState({
        id:0,
        employee:{
            name:""
        },
        picking:{
            type:0
        }
    })
    const [openNewPoint, setOpenNewPoint] = useState(false);
    const [openEditPoint, setOpenEditPoint] = useState(false);
    const [type, setType] = useState(0);
    const [hour, setHour] = useState(moment());
    const [justify, setJustify] = useState([]);
    const [justifyPanel, setJustifyPanel] = useState(false);
    const [observationPanel, setObservationPanel] = useState(false);
    const [deliveredJustification, setDeliveredJustification] = useState(0)
    const [solveIncoherence, setSolveIncoherence] = useState(0)
    const [justifySelected, setJustifySelected] = useState({name:""})
    const [, setLoader] = useRecoilState(backdrop);


    const handleClose = () => {
        setAnchorEl(null);
    };

    const columns = [
        {
            name: "Funcionário",
            selector: row=>row.employee.name,
            sortable: true,
            width:'10%',
        },
        {
            name: "Picagem",
            selector: (row) => row.picking!==null?moment(row.picking.timeEntry).format('DD-MM-YYYY HH:mm:ss'):"-",
            width:'12%',
            sortable: true,
        },
        {
            name: "Horário",
            selector: (row, idx) => row.picking!==null?row.picking.schedule.name:"",
            width:'12%',
            sortable: false,
        },
        {
            name: "Período de trabalho",
            selector: row=>row.picking!==null?(row.picking.periods!==null?row.picking.periods.timeStart+" - "+row.picking.periods.timeEnd:"-"):"-",
            width:'10%',
            sortable: true,
        },
        {
            name: "Detalhes de incoerência",
            selector: row=>{
                if(row.justify!==null){
                    // eslint-disable-next-line
                    return "Motivo: " + row.motive + " / Justificação: "+row.justify.abbreviation + " / " + (row.justify.deliveredJustification==1?"Justificação entregue":"Justificação por entregar")
                }else{
                    return "Motivo: " +row.motive
                }
            },
            width:'34%',
            sortable: true,
        },{
            name: "Tipo de picagem",
            // eslint-disable-next-line
            selector: row=>row.picking!==null?(row.picking.type==0?"Entrada":(row.picking.type==1?"Saida":"Não detetado")):"-",
            width:'10%',
            sortable: true,
        },
        {
            name: "Ações",
            cell: (row, idx) => <>
                <Button onClick={(e)=>{
                    openMenuPopper(e)
                    setPointSelected(row)
                }
                } title={"Ações rápidas"} style={{margin:5}} variant={"contained"} color={"primary"}><MenuIcon/></Button>
            </>
        }
    ];

    useEffect(()=>{
        if(date!==null) {
            setLoader(true)
            api.post('picking/inconsistencies', {
                month: date.format('MM-YYYY')
            }).then(res => {
                setInconsistencies(res.data.data)
                setLoader(false)
            })
        }

        // eslint-disable-next-line
    },[date])

    useEffect(()=>{
        api.get('/justify').then(res=>{
            // eslint-disable-next-line
            if(res.data.code==0){
                setJustify(res.data.data)
            }
        })
    },[])

    function openMenuPopper(e){
        setAnchorEl(e.currentTarget);

    }

    function saveNew(){
        setLoader(true)
        api.post('picking/inconsistencies/correction/new',{
            inconsistencies:pointSelected.id,
            hour:hour.format('HH:mm')
        }).then(res=>{
            setOpenNewPoint(false)
            if(res.data.code===0){
                Swal.fire({
                    icon: "success",
                    text: res.data.message,
                    timerProgressBar:true,
                    timer:1500
                }).then(()=> {
                    api.post('picking/inconsistencies', {
                        month: date.format('MM-YYYY')
                    }).then(res => {
                        setInconsistencies(res.data.data)
                        setLoader(false)
                    })
                })
            }else{
                setLoader(false)
                Swal.fire({
                    icon: "error",
                    text: res.data.message,
                    timerProgressBar:true,
                    timer:1500
                }).then(()=>{
                    setOpenNewPoint(true)

                })
            }
        })
    }

    function remove(){
        api.delete('picking/inconsistencies/correction/'+pointSelected.id).then(res=>{
            setOpenEditPoint(false)
            if(res.data.code===0){
                Swal.fire({
                    icon: "success",
                    text: res.data.message,
                    timerProgressBar:true,
                    timer:1500
                }).then(()=> {
                    api.post('picking/inconsistencies', {
                        month: date.format('MM-YYYY')
                    }).then(res => {
                        setInconsistencies(res.data.data)
                    })
                })
            }else{
                Swal.fire({
                    icon: "error",
                    text: res.data.message
                }).then(()=>{
                    setOpenNewPoint(true)

                })
            }
        })
    }

    function saveEdit(){
        api.post('picking/inconsistencies/correction/edit',{
            inconsistencies:pointSelected.id,
            hour:hour.format('HH:mm'),
            type:type,
        }).then(res=>{
            setOpenEditPoint(false)
            if(res.data.code===0){
                Swal.fire({
                    icon: "success",
                    text: res.data.message,
                    timer:3000
                }).then(()=> {
                    api.post('picking/inconsistencies', {
                        month: date.format('MM-YYYY')
                    }).then(res => {
                        setInconsistencies(res.data.data)
                    })
                })
            }else{
                Swal.fire({
                    icon: "error",
                    text: res.data.message
                }).then(()=>{
                    setOpenNewPoint(true)

                })
            }
        })
    }

    function saveJustify(){
        setJustifyPanel(false)
        api.post('picking/inconsistencies/correction/justify',{
            justify:justifySelected.id,
            correction:solveIncoherence,
            observation,
            inconsistencies:pointSelected.id,
        }).then(res=>{
            setOpenEditPoint(false)
            if(res.data.code===0){
                Swal.fire({
                    icon: "success",
                    text: res.data.message,
                    timer:3000
                }).then(()=> {
                    api.post('picking/inconsistencies', {
                        month: date.format('MM-YYYY')
                    }).then(res => {
                        setInconsistencies(res.data.data)
                    })
                })
            }else{
                Swal.fire({
                    icon: "error",
                    text: res.data.message
                }).then(()=>{
                    setJustifyPanel(true)

                })
            }
        })
    }

    function addObservation(){
        setJustifyPanel(false)
        api.post('picking/inconsistencies/correction/observation',{
            correction:solveIncoherence,
            observation,
            inconsistencies:pointSelected.id,
        }).then(res=>{
            setObservationPanel(false)
            if(res.data.code===0){
                Swal.fire({
                    icon: "success",
                    text: res.data.message,
                    timer:3000
                }).then(()=> {
                    api.post('picking/inconsistencies', {
                        month: date.format('MM-YYYY')
                    }).then(res => {
                        setInconsistencies(res.data.data)
                    })
                })
            }else{
                Swal.fire({
                    icon: "error",
                    text: res.data.message
                }).then(()=>{
                    setObservationPanel(true)
                })
            }
        })
    }



    return(
        <Container component="main" maxWidth="xs">
            <MuiPickersUtilsProvider libInstance={moment}  utils={MomentUtils} locale={"pt"}>
                <CssBaseline />
                <div className={classes.paper}>
                    <Card className={classes.root}>
                        <CardHeader
                            title={"Incoerências"}
                            action={
                                <>
                                    <DatePicker
                                        variant={"outlined"}
                                        ampm={false}
                                        views={['month','year']}
                                        inputFormat={"MM/YYYY"}
                                        format={"MM/YYYY"}
                                        label="Selecionar mês"
                                        maxDate={moment()}
                                        value={date}
                                        onChange={(newValue) => {
                                            setDate(newValue);
                                        }}

                                    />
                                </>}
                        />
                        <CardContent>
                            <Grid container spacing={1}>
                                {inconsistencies!==null?(
                                    <>

                                        <DataTable
                                        sortIcon={<ArrowDownwardIcon/>}
                                        columns={columns}
                                        data={inconsistencies}
                                        pagination
                                        highlightOnHover
                                        selectableRowsComponent={CheckBox}
                                        noDataComponent={"Não foram encontradas incoerências"}
                                        progressComponent={<CircularProgress/>}
                                        paginationComponentOptions={{
                                            rowsPerPageText: 'Linhas por pagina:',
                                            rangeSeparatorText: 'de',
                                            noRowsPerPage: false,
                                            selectAllRowsItem: false,
                                            selectAllRowsItemText: 'Todas'
                                        }}
                                        subHeader
                                        subHeaderComponent={<ExportButtons data={inconsistencies} columns={columns} name={"Incoerencias de "+date.format("MMMM")}/>}
                                    />
                                    </>
                                ):null}
                            </Grid>
                        </CardContent>
                    </Card>
                </div>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem disabled={pointSelected.picking===null} onClick={()=>{
                        handleClose()
                        setType(pointSelected.picking.type)
                        setHour(moment(pointSelected.picking.timeEntry))
                        setOpenEditPoint(true)
                    }

                    }>Alterar Ponto</MenuItem>
                    <MenuItem onClick={()=>{
                        handleClose()
                        setObservations(pointSelected.observation)
                        setSolveIncoherence(pointSelected.correction)
                        setDeliveredJustification(pointSelected.deliveredJustification)
                        if(pointSelected.justify!==null){
                            setObservations(pointSelected.observation)
                            setJustifySelected(pointSelected.justify)

                        }
                        setJustifyPanel(true)
                    }}>Justificação</MenuItem>
                    <MenuItem onClick={()=>{
                        handleClose()
                        setObservations(pointSelected.observation)
                        setObservationPanel(true)
                    }}>Observações</MenuItem>
                    <MenuItem onClick={()=>{
                        handleClose()
                        if(pointSelected.picking!==null){
                            if(pointSelected.picking.type!==null){
                                // eslint-disable-next-line
                                if(pointSelected.picking.type==1) {
                                    setHour(moment(pointSelected.picking.periods.timeStart, 'HH:mm'))
                                }else{
                                    setHour(moment(pointSelected.picking.periods.timeEnd, 'HH:mm'))
                                }
                            }else{
                                setHour(moment(pointSelected.picking.periods.timeStart, 'HH:mm'))
                            }
                        }
                        setOpenNewPoint(true)
                    }}>Novo Ponto</MenuItem>
                </Menu>

                <Dialog maxWidth={'sm'} fullWidth open={openNewPoint} onClose={()=>setOpenNewPoint(false)}>
                    <DialogTitle>Adicionar Ponto - {pointSelected.employee.name}</DialogTitle>
                    <DialogContent >
                        <Grid container xs={12}>
                            <Grid item xs={6}>
                                <TimePicker label={"Hora de picagem"} ampm={false} value={hour} onChange={data=>setHour(data)} format={"MM/YYYY"} invalidDateMessage={"A data introduzida não é valida"} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label={"Dia de picagem"} disabled value={pointSelected.date} />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>setOpenNewPoint(false)}>Cancelar</Button>
                        <Button onClick={()=>saveNew()}>Guardar</Button>
                    </DialogActions>
                </Dialog>

                <Dialog maxWidth={'sm'} fullWidth open={openEditPoint} onClose={()=>setOpenEditPoint(false)}>
                    <DialogTitle>
                        Editar Ponto - {pointSelected.employee.name}
                        <IconButton
                            aria-label="close"
                            onClick={remove}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <Delete style={{color:'red'}} />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent >
                        {pointSelected.picking!==null?(
                            <Grid container xs={12} spacing={5} style={{marginTop:5}}>
                                <Grid item xs={6}>
                                    <TimePicker label={"Hora de picagem"}  ampm={false} value={hour} onChange={data=>setHour(data)}  renderInput={(params) => <TextField {...params} />}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField label={"Dia de picagem"}  disabled value={pointSelected.date} />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <InputLabel>Tipo de picagem</InputLabel>
                                        <Select value={type} onChange={e=>setType(e.target.value)} fullWidth label="Tipo de picagem">
                                            <MenuItem fullWidth selected disabled>Escolher uma opção</MenuItem>
                                            <MenuItem value={0} fullWidth>Entrada</MenuItem>
                                            <MenuItem value={1} fullWidth>Saida</MenuItem>
                                        </Select>
                                        <FormHelperText>Entrada ou Saida</FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        ):null}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>setOpenEditPoint(false)}>Cancelar</Button>
                        <Button onClick={()=>saveEdit()}>Guardar</Button>
                    </DialogActions>
                </Dialog>

                <Dialog maxWidth={'sm'} fullWidth open={justifyPanel} onClose={()=> setJustifyPanel(false)}>
                    <DialogTitle>Justificar Incoerência</DialogTitle>
                    <DialogContent >
                        <Grid container spacing={2} style={{marginTop:4}}>
                            <Grid item xs={12}>
                                <Autocomplete
                                    onChange={(e, newValue)=> setJustifySelected(newValue)}
                                    value={justifySelected}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <TextField {...params} label="Justificação" />}
                                    options={justify}
                                    noOptionsText={"Não foi encontradas justificações"}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField onChange={e=>setObservations(e.target.value)} value={observation} fullWidth multiline label={"Observações"}/>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel>Justificação Entregue</InputLabel>
                                    <Select value={deliveredJustification} fullWidth={true} onChange={e=>setDeliveredJustification(e.target.value)}  label={"Justificação Entregue"}>
                                        <MenuItem value={1} fullWidth={true}>Sim</MenuItem>
                                        <MenuItem value={0} fullWidth={true}>Não</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel>Incoerência resolvida</InputLabel>
                                    <Select value={solveIncoherence} fullWidth={true} onChange={e=>setSolveIncoherence(e.target.value)} label={"Incoerência resolvida"}>
                                        <MenuItem value={1} fullWidth={true}>Sim</MenuItem>
                                        <MenuItem value={0} fullWidth={true}>Não</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>setJustifyPanel(false)}>Cancelar</Button>
                        <Button onClick={()=>saveJustify()}>Guardar</Button>
                    </DialogActions>
                </Dialog>

                <Dialog maxWidth={'sm'} fullWidth open={observationPanel} onClose={()=> setObservationPanel(false)}>
                    <DialogTitle>Justificar Incoerência</DialogTitle>
                    <DialogContent >
                        <Grid container spacing={2} style={{marginTop:4}}>
                            <Grid item xs={12}>
                                <TextField onChange={e=>setObservations(e.target.value)} value={observation} fullWidth multiline label={"Observações"}/>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel>Incoerência resolvida</InputLabel>
                                    <Select value={solveIncoherence} fullWidth={true} onChange={e=>setSolveIncoherence(e.target.value)} label={"Incoerência resolvida"}>
                                        <MenuItem value={1} fullWidth={true}>Sim</MenuItem>
                                        <MenuItem value={0} fullWidth={true}>Não</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>setObservationPanel(false)}>Cancelar</Button>
                        <Button onClick={()=>addObservation()}>Guardar</Button>
                    </DialogActions>
                </Dialog>
            </MuiPickersUtilsProvider>
        </Container>
    )
}