import * as React from 'react';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {Backdrop, Button, CircularProgress, Collapse,  IconButton, Menu, MenuItem} from "@material-ui/core";
import {logout, username} from "../services/auth";
import {useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {
    CalendarToday,
    CheckCircleOutline, ChevronLeft, ChevronRight, ExpandLess, ExpandMore,
    Group, Home,
    Person, PinDrop, Report,
    Room,
    Settings,
    Timer, ViewDay
} from "@material-ui/icons";
import useStyles from "../utils/useStyles";
import clsx from "clsx";
import MenuIcon from "@material-ui/icons/Menu";
import {useTheme} from "@emotion/react";
import {useRecoilState} from "recoil";
import {backdrop} from "../atom";

//const drawerWidth = 240;

/*const useStyles = makeStyles((theme) => ({
    nested: {
        marginLeft: theme.spacing(4),
        //color:"rgba(255,255,255,0.8)"
    },
}));*/

export default function Template(props) {
    const classes = useStyles();

    const history = useHistory();

    const theme = useTheme();

    const [anchorEl, setAnchorEl] = useState(null);
    const [settings, setSettings] = useState(false)
    const [reports, setReports] = useState(false)

    const [openMenu, setOpenMenu] = React.useState(false);
    const [open, ] = useRecoilState(backdrop);


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDrawerOpen = () => {
        setOpenMenu(true);
    };

    const handleDrawerClose = () => {
        setOpenMenu(false);
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar

                color={"primary"}
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: openMenu,
                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, openMenu && classes.hide)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography className={classes.title}  variant="h6" noWrap>
                        Relógio de Ponto
                    </Typography>
                    <Button variant="contained" color="primary" ria-haspopup="true" onClick={handleClick}>
                        {username()}
                    </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={()=>{
                            logout()
                            handleClose()
                            history.push("/")

                        }}>Terminar sessão</MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={openMenu}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
                    </IconButton>
                </div>
                <Box sx={{ overflow: 'auto' }}>
                    <List disablePadding>
                        <Link to={"/dashboard"}>
                            <ListItem button>
                                <ListItemIcon>
                                    <Home style={{color:"#fff"}} />
                                </ListItemIcon>
                                <ListItemText style={{color:"#fff"}} primary={"Painel"} />
                            </ListItem>
                        </Link>
                        <Link to={"/picking/list"}>
                            <ListItem button>
                                <ListItemIcon>
                                    <PinDrop style={{color:"#fff"}} />
                                </ListItemIcon>
                                <ListItemText style={{color:"#fff"}} primary={"Picagens"} />
                            </ListItem>
                        </Link>
                        <Link to={"/employee"}>
                            <ListItem button>
                                <ListItemIcon>
                                    <Person style={{color:"#fff"}}  />
                                </ListItemIcon>
                                <ListItemText style={{color:"#fff"}} primary={"Funcionários"} />
                            </ListItem>
                        </Link>
                        <Link to={"/picking/inconsistencies"}>
                            <ListItem button>
                                <ListItemIcon>
                                    <Room style={{color:"#fff"}} />
                                </ListItemIcon>
                                <ListItemText style={{color:"#fff"}} primary={"Incoerências"} />
                            </ListItem>
                        </Link>
                        {/*<ListItem button>
                            <ListItemIcon>
                                <Room style={{color:"#fff"}} />
                            </ListItemIcon>
                            <ListItemText style={{color:"#fff"}} primary={"Bolsa de Horas"} />
                        </ListItem>*/}
                        {/*<ListItem button>
                            <ListItemIcon>
                                <Room style={{color:"#fff"}} />
                            </ListItemIcon>
                            <ListItemText style={{color:"#fff"}} primary={"Mapa"} />
                        </ListItem>*/}
                        <Link to={"/holidays"}>
                            <ListItem button>
                                <ListItemIcon>
                                    <Room style={{color:"#fff"}} />
                                </ListItemIcon>
                                <ListItemText style={{color:"#fff"}} primary={"Ferias / Justificações"} />
                            </ListItem>
                        </Link>
                        {/*<ListItem button>
                            <ListItemIcon>
                                <Room style={{color:"#fff"}} />
                            </ListItemIcon>
                            <ListItemText style={{color:"#fff"}} primary={"Local de trabalho"} />
                        </ListItem>*/}

                        {/*<ListItem button>
                            <ListItemIcon>
                                <Room style={{color:"#fff"}} />
                            </ListItemIcon>
                            <ListItemText style={{color:"#fff"}} primary={"Mapa"} />
                        </ListItem>*/}
                    </List>
                    <Divider />
                    <List disablePadding>
                        <ListItem button onClick={()=>setSettings(!settings)}>
                            <ListItemIcon>
                                <Settings style={{color:"#fff"}} />
                            </ListItemIcon>
                            <ListItemText primary={"Gestão"} />
                            <ListItemIcon style={{marginLeft:'50%'}}>
                                {!settings?<ExpandMore style={{color:"#fff"}}/>:<ExpandLess style={{color:"#fff"}}/>}
                            </ListItemIcon>
                        </ListItem>
                        <Collapse in={settings} timeout="auto" unmountOnExit>
                            <Divider />
                            <List component="div" disablePadding>
                                <Link to={"/department"}>
                                    <ListItem button className={classes.nested} >
                                        <ListItemIcon>
                                            <Group style={{color:"#fff"}} />
                                        </ListItemIcon>
                                        <ListItemText primary="Departamentos" />
                                    </ListItem>
                                </Link>
                            </List>
                            <List component="div" disablePadding>
                                <Link to={"/schedule"}>
                                    <ListItem button className={classes.nested} >
                                        <ListItemIcon>
                                            <Timer style={{color:"#fff"}}  />
                                        </ListItemIcon>
                                        <ListItemText primary="Horários" />
                                    </ListItem>
                                </Link>
                            </List>
                            <List component="div" disablePadding>
                                <Link to={"/justify"}>
                                    <ListItem button className={classes.nested} >
                                        <ListItemIcon>
                                            <CheckCircleOutline style={{color:"#fff"}}  />
                                        </ListItemIcon>
                                        <ListItemText primary="Justificações" />
                                    </ListItem>
                                </Link>
                            </List>
                            <List component="div" disablePadding>
                                <Link to={"/user"}>
                                    <ListItem button className={classes.nested} >
                                        <ListItemIcon>
                                            <Group style={{color:"#fff"}}  />
                                        </ListItemIcon>
                                        <ListItemText primary="Utilizadores" />
                                    </ListItem>
                                </Link>
                            </List>
                            <Divider />
                        </Collapse>
                    </List>
                    <List disablePadding>
                        <ListItem button onClick={()=>setReports(!reports)}>
                            <ListItemIcon>
                                <Report style={{color:"#fff"}}  />
                            </ListItemIcon>
                            <ListItemText primary={"Relatórios"} />
                            <ListItemIcon style={{marginLeft:'50%'}}>
                                {!reports?<ExpandMore style={{color:"#fff"}}/>:<ExpandLess style={{color:"#fff"}}/>}
                            </ListItemIcon>
                        </ListItem>
                        <Collapse in={reports} timeout="auto" unmountOnExit >
                            <Divider />
                            <List component="div" disablePadding >
                                <Link to={"/reports/diary"}>
                                    <ListItem button className={classes.nested} >
                                        <ListItemIcon>
                                            <ViewDay style={{color:"#fff"}}  />
                                        </ListItemIcon>
                                        <ListItemText primary="Diário" />
                                    </ListItem>
                                </Link>
                            </List>
                            <List component="div" disablePadding>
                                <Link to={"/reports/month"}>
                                    <ListItem button className={classes.nested} >
                                        <ListItemIcon>
                                            <CalendarToday style={{color:"#fff"}}  />
                                        </ListItemIcon>
                                        <ListItemText primary="Mensal" />
                                    </ListItem>
                                </Link>
                            </List>
                            <Divider />
                        </Collapse>
                    </List>
                </Box>
            </Drawer>
            <main
                className={clsx(classes.content, {
                    [classes.contentShift]: openMenu,
                })}
            >
                <div className={classes.drawerHeader}/>
                    {/*<CssBaseline />*/}
                {props.children}
            </main>

            <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}
